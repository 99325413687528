<template>
  <div class="appDetail">
    <div class="mobile-dailog">
      <div class="dailog-line1">
        <img :src="detailData.appIconUrl" class="dailogTypeImg">
        <div class="right">
          <p class="title">
            {{ detailData.appName }}
          </p>
          <div class="version">
            当前版本：{{ detailData.editionNo }}
          </div>
        </div>
      </div>
      <div class="dailog-line2">
        <div class="type">
          适用年级：
        </div>
        <div class="val">
          <span v-for="(v,i) in detailData.grades" :key="i"><span v-show="i!==0">、</span>{{ v.name }}</span>
        </div>
      </div>
      <div class="dailog-line2 dailog-line3">
        <div class="type">
          适用学科：
        </div>
        <div class="val">
          <span v-for="(v,i) in detailData.subjects" :key="i"><span v-show="i!==0">、</span>{{ v.name }}</span>
        </div>
      </div>
      <div class="dailog-line2 dailog-line3">
        <div class="type">
          应用简介：
        </div>
        <div class="val">
          {{ detailData.introduction }}
        </div>
      </div>
      <div class="dailog-line2">
        <div class="type">
          应用详情：
        </div>
        <div class="val" v-html="detailData.details" />
      </div>
    </div>
  </div>
</template>
<script>
import { getDetail } from '@/api/edu.js';
export default {
  data() {
    return {
      detailData: {}
    };
  },
  mounted() {
    if (this.$route.query.id) {
      const id = this.$route.query.id;
      this.getDetail(id);
    }
  },
  methods: {
    getDetail(id) {
      getDetail(id).then(res => {
        this.detailData = res.data;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.appDetail{
    .mobile-dailog{
        background: #ffffff;
        padding: 15px;
        .dailog-line1{
            display: flex;
            align-items: center;
            border-bottom: 1px dashed #F0F0F0;
            padding-bottom: 20px;
            margin-bottom: 20px;
            .dailogTypeImg{
                width: 100px;
                height: 100px;
            }
            .right{
                margin-left: 20px;
                .title{
                    font-size: 24px;
                    font-weight: bold;
                    color: #333333;
                }
                .version{
                    font-size: 15px;
                    font-weight: 400;
                    color: #666666;
                }
            }
        }
        .dailog-line3{
            margin: 15px 0;
        }
        .dailog-line2{
            font-size: 15px;
            color: #333333;
            display: flex;
            .type{
                font-weight: bold;
                width: 25%;
            }
            .val{
                font-weight: 400;
                width: 75%;
            }
        }
    }
}
</style>
